<template>
  <div class="d-flex no-wrap dropdown">
    <button
      class="btn btn-secondary btn-sm dropdown-btn"
      data-bs-toggle="dropdown"
      aria-expanded="true"
    >
      <span :class="{ 'loading-spin': loadPrintBtn }">
        <BaseIcon name="printer" />
      </span>
    </button>
    <div
      class="dropdown-menu"
      aria-labelledby="Printing options"
      data-popper-placement="bottom-start"
    >
      <div class="row">
        <div class="col-12">
          <button
            class="dropdown-item regular-12"
            v-on:click="downloadProductionPdf()"
          >
            <BaseIcon name="task-list" />
            {{ $t("incomingProducts.productionPages") }}
          </button>
          <button
            class="dropdown-item regular-12"
            v-on:click="downloadProductsLabels()"
          >
            <BaseIcon name="barcode" />
            {{ $t("incomingProducts.productLabels") }}
          </button>
          <button
            class="dropdown-item regular-12"
            v-on:click="downloadBoxesLabels()"
          >
            <BaseIcon name="box" />
            {{ $t("incomingProducts.boxLabels") }}
          </button>
          <button
            class="dropdown-item regular-12"
            v-on:click="printIngredients()"
          >
            <BaseIcon name="printer" />
            {{ $t("ingredients.ingredients") }}
          </button>
          <button
            class="dropdown-item regular-12"
            v-on:click="printOperations()"
          >
            <BaseIcon name="printer" />
            {{ $t("itemOperationPrints.operations") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseIcon from "@/components/icons/BaseIcon";
import { useStore } from "vuex";
import tenant from "../../../modules/tenant";
import printJS from "print-js";

export default {
  name: "ItemOperationPrints",
  components: { BaseIcon },
  props: {
    id: Number,
    row: Object,
    modelValue: Array,
  },
  data() {
    let store = useStore();
    return {
      store: store,
      loadPrintBtn: false,
    };
  },
  methods: {
    showModal() {
      this.$refs.modal.showModal();
    },
    printOperations() {
      window.open(
        `${tenant.getApiBaseUrl()}/products/manufacturing-operations/pdf?_token=${
          this.store.state.token
        }&id[]=${this.modelValue.product_id}`,
        "_blank"
      );
    },
    printIngredients() {
      window.open(
        `${tenant.getApiBaseUrl()}/products/ingredients/pdf?_token=${
          this.store.state.token
        }&id[]=${this.modelValue.product_id}`,
        "_blank"
      );
    },
    downloadProductionPdf() {
      const _this = this;
      this.loadPrintBtn = true;
      let url = `${tenant.getApiBaseUrl()}/production/${
        this.modelValue.warehouse_operation_id
      }/pdf/?items[]=${this.modelValue.item_id}&_token=${
        this.store.state.token
      }`;
      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(url, "_blank");
        _this.loadPrintBtn = false;
      } else {
        printJS({
          printable: url,
          onLoadingEnd: function () {
            _this.loadPrintBtn = false;
          },
        });
      }
    },
    downloadProductsLabels(currency = "") {
      const _this = this;
      this.loadPrintBtn = true;
      let url = `${tenant.getApiBaseUrl()}/production/${
        this.modelValue.warehouse_operation_id
      }/barcode/?items[]=${this.modelValue.item_id}&_token=${
        this.store.state.token
      }&currency=${currency}`;
      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(url, "_blank");
        _this.loadPrintBtn = false;
      } else {
        printJS({
          printable: url,
          onLoadingEnd: function () {
            _this.loadPrintBtn = false;
          },
        });
      }
    },
    downloadBoxesLabels() {
      const _this = this;
      this.loadPrintBtn = true;
      let url = `${tenant.getApiBaseUrl()}/production/${
        this.modelValue.warehouse_operation_id
      }/box-label/?items[]=${this.modelValue.item_id}&_token=${
        this.store.state.token
      }`;
      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(url, "_blank");
        _this.loadPrintBtn = false;
      } else {
        printJS({
          printable: url,
          onLoadingEnd: function () {
            _this.loadPrintBtn = false;
          },
        });
      }
    },
  },
  emits: ["update:modelValue", "update"],
};
</script>
